import { Row, Col, Card } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};

const Cosmetique = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getImages = (x, path, ext) => {
    x++;

    return path + x + ext;
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">cosmétique</div>
        </Row>
      </div>
      <Row>
        <Col span={20} push={2}>
          <Card
            style={{ height: "100%", marginTop: "3vh" }}
            className="bestlab--card-title"
            title="Lella Zwina"
            bordered={false}
          >
            <Row>
              <Col span={24}>
                <div>
                  <Slider {...settings}>
                    {Array.apply(null, Array(4)).map((el, x) => (
                      <div>
                        <img
                          className="bl--slider-img bl--slider-about "
                          src={getImages(
                            x,
                            "/images/cosmetique/lella_zwina/",
                            ".jpg"
                          )}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={20} push={2}>
          <Card
            style={{ height: "100%", marginTop: "5vh" }}
            className="bestlab--card-title"
            title="Touche d'or"
            bordered={false}
          >
            <Row>
              <Col span={24}>
                <div>
                  <Slider {...settings}>
                    {Array.apply(null, Array(7)).map((el, x) => (
                      <div>
                        <img
                          className="bl--slider-img bl--slider-about "
                          src={getImages(
                            x,
                            "/images/cosmetique/touche_d_or/",
                            ".jpg"
                          )}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            marginTop: "8vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Cosmetique;
