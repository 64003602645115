import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Tables = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/tables/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">Table anti-vibration</div>
        </Row>
        <Row
          style={{
            backgroundColor: "#00738e",
            paddingBottom: "4vh",
            marginTop: "2vh"
          }}
        >
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/table.png" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              La Table Anti-Vibration aide à fournir une surface stable et
              réduit les vibrations, les chocs et les influences externes
              agissant sur les systèmes placés sur la surface de travail en
              marbre épaisseur 10 cm. Conçue pour le laboratoire ou les salles
              de métrologie la table anti-vibration dispose d'une conception
              ergonomique et construite pour durer.
              <br /> Ossature métallique résistante aux agents agressifs Pieds
              réglables de mise à niveau Plaque en grès étiré résistante aux
              éraflures Finition polie La table comprend deux parties séparées :
              <br />
              Une surface de travail interne composée d'une large plaque de grés
              montée sur des supports en caoutchouc antichocs réglables. Plan de
              travail lisse et sans jointure et pieds réglables de mise à
              niveau.
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>

      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(8)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
            marginTop: "2vh"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Tables;
