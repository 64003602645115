import { Row, Col, Button } from "antd";
import { useEffect } from "react";
import CountUp from "react-countup";
import ScrollToTop from "react-scroll-to-top";
import { fadeIn } from "react-animations";
import { slideInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Link } from "react-router-dom";
import { Player } from "video-react";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  slideInUp: {
    animation: "x 2s",
    animationName: Radium.keyframes(slideInUp, "slideInUp")
  }
};
const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const firstElement = (
    <div className="bl--first-block">
      <img
        className="bl--img-home"
        src="/images/best_lab_blanc.png"
        style={styles.slideInUp}
      />
      <div style={{ marginTop: "4vh" }}>
        <span className="bl--title-home" style={styles.slideInUp}>
          Bio-Equipements Sciences Technologie de Laboratoire
        </span>
      </div>
      <div style={{ marginTop: "4vh" }}>
        <span className="bl--subtitle-home" style={styles.slideInUp}>
          The <span className="bl--subtitle-home-big">Best</span> for a
          Reason...
        </span>
      </div>
    </div>
  );

  const secondElement = (
    <>
      <Row style={{ padding: "4vh" }}>
        <Col xl={2} md={0} xs={0} />
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/1.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasse Revêtement</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Plan de travail en grès antiacide
              </div>
              <div className="bl--subtitle-img">
                Plan de travail en verre émmailée
              </div>
              <div className="bl--subtitle-img">
                Plan de travail en composite
              </div>
              <div className="bl--subtitle-img">Pal de travail en Quartez</div>
            </div>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/2.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasses murales</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Paillasse murale sans dosseret
              </div>
              <div className="bl--subtitle-img">
                Paillasse murale avec dosseret / tablette{" "}
              </div>
            </div>
          </div>
        </Col>

        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/3.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasses centrales</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Paillasse centrale avec dosseret / tablette
              </div>
              <div className="bl--subtitle-img">
                Paillasse centrale avec cuve
              </div>
            </div>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12}>
          <div class="container">
            <img src="/images/4.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Meubles sous paillasses</span>
            </div>
            <div>
              <div className="bl--subtitle-img">Meuble à deux portes</div>
              <div className="bl--subtitle-img">
                Meuble à porte avec tiroirs
              </div>
              <div className="bl--subtitle-img">Meuble à tiroirs</div>
            </div>
          </div>
        </Col>
        <Col xl={2} md={0} xs={0} />

        <Col xl={2} md={0} xs={0} />
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/5.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <span className="bl--title-img">Hotte Sorbonne</span>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/6.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <span className="bl--title-img">Table de pesé</span>
          </div>
        </Col>

        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/7.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Cuve et Robinetterie</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Robinet eau chaude / eau froide
              </div>
              <div className="bl--subtitle-img">Air comprimé</div>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ textAlign: "center", paddingBottom: "1vh" }}>
        <p className="bl--paragraph-service">
          Agencement de Labo: Paillasses, hottes
        </p>
        <p className="bl--paragraph-service">
          Conception et installation : Stations d’eau osmosée et bi-osmosée
        </p>
      </div>
    </>
  );

  const thirdElement = (
    <Row style={{ padding: "4vh" }}>
      <Col xl={8} md={4} xs={0}></Col>
      <Col xl={4} md={8} xs={12}>
        <div className="bl--first-circle">
          <p style={{ textAlign: "center", padding: "4vh" }}>
            <span className="bl--nb-projects">
              {" "}
              <CountUp
                start={0}
                end={300}
                duration={4}
                separator=" "
                decimals={0}
                decimal=","
                prefix="+ "
              />
            </span>
            <p className="bl--projects">Projets réalisés</p>
          </p>
        </div>
      </Col>
      <Col xl={4} md={8} xs={12}>
        <div className="bl--third-circle">
          <div className="bl--second-circle">
            <p style={{ textAlign: "center", padding: "4vh" }}>
              <span className="bl--large">
                un large éventail <br />
                de matériels
                <br /> de laboratoire
              </span>
            </p>
          </div>
        </div>
      </Col>
      <Col xl={8} md={4} xs={0}></Col>
    </Row>
  );

  const fourthElement = (
    <Row style={{ padding: "2vh" }} className="bl--bloc-video">
      <Col xl={2} md={2} xs={0} />
      <Col xl={9} md={22} xs={24}>
        <Player
          autoPlay
          playsInline
          poster="/images/best_lab_blue.png"
          src="/images/intro.mp4"
        />
      </Col>
      <Col xl={1} md={2} xs={0} />

      <Col xl={10} md={22} xs={24} style={{ marginTop: "1vh" }}>
        <span className="bl--bloc-video-title">Notre savoir faire</span>
        <p className="bl--bloc-video-paragraph">
          BEST LAB vous propose les produits les plus innovants ainsi que les
          équipements scientifiques et de laboratoire qui ont vraiment fait
          leurs preuves, un savoir-faire et une expérience de plusieurs années
          aux services de nos clients.
        </p>
        <div>
          <Link to="/services" onClick={() => window.location.reload()}>
            <Button className="bl--bloc-video-button">savoir plus</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
  const fifthElement = (
    <>
      <div
        className="bl--background-block"
        style={{ minHeight: "40vh", padding: "2vh" }}
      >
        <Row justify="center">
          <Col span="24" style={{ textAlign: "center" }}>
            <span
              style={{
                fontSize: "52px",
                color: "#444644",
                fontFamily: "Montserrat"
              }}
            >
              {" "}
              Nos références
            </span>
          </Col>
        </Row>
        <Row flex="1">
          <Col flex={1}>
            <Link to="/pharmacie">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <img
                      src="/images/service.jpg"
                      alt="Pharmacie"
                      style={{ width: "300px", height: "200px" }}
                    />
                  </div>
                  <div class="flip-box-back">
                    <p>Pharmacie</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col flex={1}>
            <Link to="/agro-alimentaire">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <img
                      src="/images/agricultural.jpg"
                      alt="Agro alimentaire"
                      style={{ width: "300px", height: "200px" }}
                    />
                  </div>
                  <div class="flip-box-back">
                    <p>Agro alimentaire</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col flex={1}>
            <Link to="/cosmetique">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <img
                      src="/images/cosmetique.jpg"
                      style={{ width: "300px", height: "200px" }}
                    />
                  </div>
                  <div class="flip-box-back">
                    <p>cosmétique</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col flex={1}>
            <Link to="/enseignement">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <img
                      src="/images/enseignement.JPG"
                      style={{ width: "300px", height: "200px" }}
                    />
                  </div>
                  <div class="flip-box-back">
                    <p>enseignement</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col flex={1}>
            <Link to="/industrie">
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <img
                      src="/images/industrie.jpg"
                      style={{ width: "300px", height: "200px" }}
                    />
                  </div>
                  <div class="flip-box-back">
                    <p>Industrie</p>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
      {/* <div
        style={{
          minHeight: "6vh",
          backgroundColor: "#00738e",
          textAlign: "center",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
            paddingTop: "10px",
            fontSize: "1.5em",
          }}
        >
          {" "}
          Vente équipements, Verreries,accessoires et Produits chimiques
        </p>
      </div> */}
    </>
  );

  return (
    <StyleRoot>
      <div style={styles.fadeIn}>{firstElement}</div>
      {/* <div style={styles.fadeIn} className="bl--background-block">
        {secondElement}
      </div> */}
      {thirdElement}
      {fourthElement}
      {fifthElement}
      <ScrollToTop smooth />
    </StyleRoot>
  );
};

export default Home;
