import { Row, Col, Card, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Mobiliers = () => {
  const [selected, setSelected] = useState({ nb: 1, title: "" });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return selected.nb == 3
      ? "/images/mobilier/poignee/filante/" + x + ".jpg"
      : "/images/mobilier/poignee/ponctuelle/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">mobiliers de laboratoire</div>
        </Row>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/mob.jpg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              Caissons mobile ou fixe sous paillasse
            </p>
            <p className="bl--description">
              Notre sociéte fabrique des caissons pourvus de quatre roulettes
              dont deux à frein ou fixe sur sabots il se positionnent selon vos
              besoins sous tout le linéaire de paillasse. <br />
              La conception et la fabrication des caissons sur mesure et sur
              demande. <br />
              Façade en MDF statifié ou en hydrofuge ou en maxi Gloss :
              <br />
              surface extrêmement brillante, effet miroir, haute résistance aux
              rayures et haute résistance chimique aux liquides, détergents et
              tâches.
              <br />
              Pour toute les façades la préhension est assurée par une poingée
              filante ou ponctuelle, les charnières sont réglables et autorisent
              une ouverture à 110°.
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={8} md={8} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Poingnée ponctuelle en acier"
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({ nb: 4, title: "Poingnée ponctuelle en acier" })
              }
            >
              <img src="/images/mobilier/3.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Poignée filante"
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => setSelected({ nb: 3, title: "Poingnée filante" })}
            >
              <img src="/images/mobilier/2.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={24} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Charnières 110°"
            bordered={false}
          >
            <div style={{ textAlign: "center" }}>
              <img src="/images/mobilier/1.png"></img>
            </div>
          </Card>
        </Col>
      </Row>
      <Card
        style={{ height: "100%", marginTop: "1vh", textAlign: "center" }}
        className="bestlab--card-title"
        title="Installation des caissons sous paillasses sèches"
        bordered={false}
      >
        <Row>
          <Col xl={8} md={12} xs={24}>
            <div style={{ textAlign: "center" }}>
              <img src="/images/mobilier/4-1.png" />
              <img
                src="/images/mobilier/caissons/2.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 2 portes</p>
            </div>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-2.png" />
              <img
                src="/images/mobilier/caissons/6.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 2 portes</p>
            </div>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-3.png" />
              <img
                src="/images/mobilier/caissons/1.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 1 porte</p>
            </div>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-4.png" />
              <img
                src="/images/mobilier/caissons/7.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 2 porte et 2 tiroirs</p>
            </div>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-5.png" />
              <img
                src="/images/mobilier/caissons/3.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 1 porte et 1 tiroir</p>
            </div>
          </Col>
          <Col xl={8} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-6.png" />
              <img
                src="/images/mobilier/caissons/8.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 3 tiroirs</p>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-7.png" />
              <img
                src="/images/mobilier/caissons/2.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 2 portes</p>
            </div>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <div>
              <img src="/images/mobilier/4-8.png" />
              <img
                src="/images/mobilier/caissons/4.png"
                style={{ width: "20%", marginLeft: "2vh" }}
              />
              <p className="bl--title-el">Meuble à 1 porte</p>
            </div>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col xl={12} md={12} xs={24}>
          {" "}
          <Card
            style={{ height: "100%", marginTop: "1vh", textAlign: "center" }}
            className="bestlab--card-title"
            title="Des meubles suspendus avec étagère"
            bordered={false}
          >
            <div style={{ textAlign: "center" }}>
              <img src="/images/mobilier/5.png" />
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          {" "}
          <Card
            style={{ height: "100%", marginTop: "1vh", textAlign: "center" }}
            className="bestlab--card-title"
            title="Des meubles hauts"
            bordered={false}
          >
            <div style={{ textAlign: "center" }}>
              <img src="/images/mobilier/6.png" />
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        footer={[]}
        width="80%"
        style={{ top: 20 }}
        onCancel={() => setSelected({ nb: 0, title: "" })}
        visible={selected.title != ""}
      >
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">{selected.title}</div>
        </Row>
        <div>
          <Row style={{ padding: "4vh" }}>
            <Col span={24}>
              <div>
                <Slider {...settings}>
                  {Array.apply(null, Array(selected.nb)).map((el, x) => (
                    <div>
                      <img
                        className="bl--slider-img bl--slider-about "
                        src={getImages(x)}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>{" "}
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
            marginTop: "2vh"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Mobiliers;
