import { Row, Col, Modal, Card, Button } from "antd";
import { useEffect, useState } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const TypePaillase = () => {
  const data = [3, 5, 5, 4, 3, 4, 3, 4];
  const [selected, setSelected] = useState({ nb: -1, title: "" });
  useEffect(() => {
    console.log(data[selected.nb - 1]);
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/paillase/slider/" + selected.nb + "/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">les types de paillasses</div>
        </Row>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/service.jpg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              Nos paillasses sont montées sur une ossature métallique se décline
              en des profondeurs de piétements 560, 600 et 700 mm selon le plan
              de travail et peuvent être personnalisées selon vos besoins par
              l'ajout d'un dosseret, d'un dosseret-tablette ou tout autre
              finition.
              <br /> Piètement métallique protégé par résine époxy cuite au
              four, couleur RAL 7035 ou autre supporter avec 4 vérins réglables
              de mise à niveau, toute la structure de la paillasse sera
              démontable.
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={8} md={8} xs={12}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses <br /> humide
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => setSelected({ nb: 1, title: "Paillasses humide" })}
            >
              <img src="/images/paillase/1.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses <br /> sèches
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => setSelected({ nb: 2, title: "Paillasses sèches" })}
            >
              <img src="/images/paillase/2.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                Paillasses humides avec dosserets <br /> et tablettes
              </div>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({
                  nb: 3,
                  title: "Paillasses humides avec dosserets et tablettes"
                })
              }
            >
              <img src="/images/paillase/3.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses sèches <br /> avec dosserets et tablettes
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({
                  nb: 4,
                  title: "Paillasses sèches avec dosserets et tablettes"
                })
              }
            >
              <img src="/images/paillase/4.PNG"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses centrales <br /> avec dosserets et tablettes
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({
                  nb: 5,
                  title: "Paillasses centrales avec dosserets et tablettes"
                })
              }
            >
              <img src="/images/paillase/5.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses centrales <br /> avec étagère
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({
                  nb: 6,
                  title: "Paillasses centrales avec étagère"
                })
              }
            >
              <img src="/images/paillase/6.PNG"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses centrales <br /> avec cuve
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({ nb: 7, title: "Paillasses centrales avec cuve" })
              }
            >
              <img src="/images/paillase/7.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={12} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <>
                Paillasses centrales <br /> avec caisson
              </>
            }
            bordered={false}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() =>
                setSelected({
                  nb: 8,
                  title: "Paillasses centrales avec caisson"
                })
              }
            >
              <img src="/images/paillase/8.png"></img>
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        footer={[]}
        width="80%"
        style={{ top: 20 }}
        onCancel={() => setSelected({ nb: -1, title: "" })}
        visible={selected.nb != -1}
      >
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">{selected.title}</div>
        </Row>
        <div>
          <Row style={{ padding: "4vh" }}>
            <Col span={24}>
              <div>
                <Slider {...settings}>
                  {Array.apply(null, Array(data[selected.nb - 1])).map(
                    (el, x) => (
                      <div>
                        <img
                          className="bl--slider-img bl--slider-about "
                          src={getImages(x)}
                        />
                      </div>
                    )
                  )}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default TypePaillase;
