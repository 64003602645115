import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import "./App.css";
import TopNavLayout from "./Layouts/TopNavigationLayout";
import Home from "./pages/Home";
import history from "./utils/History";
import "./Styles/components.scss";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import About from "./pages/About";
import Utilite from "./pages/Services/Utilite";
import PlanTravail from "./pages/Services/PlanTravail";
import Pharmacie from "./pages/reference/Pharmacie";
import Agro from "./pages/reference/Agroalimentaire";
import Enseignement from "./pages/reference/Enseignement";
import Cosmetique from "./pages/reference/Cosmetique";
import Industrie from "./pages/reference/Industrie";
import TypePaillase from "./pages/Services/TypePaillase";
import Laverie from "./pages/Services/Laverie";
import ArmoireSecurite from "./pages/Services/ArmoireSecurite";
import Mobiliers from "./pages/Services/Mobiliers";
import Tabourets from "./pages/Services/Tabourets";
import Tables from "./pages/Services/Tables";
import Douches from "./pages/Services/Douches";
import HotteSorbonne from "./pages/Services/HotteSorbonne";

function App() {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route
          path="/"
          exact={true}
          element={
            <TopNavLayout>
              <Home />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/contact"
          exact={true}
          element={
            <TopNavLayout>
              <Contact />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/services"
          exact={true}
          element={
            <TopNavLayout>
              <Services />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/utilite"
          exact={true}
          element={
            <TopNavLayout>
              <Utilite />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/pharmacie"
          exact={true}
          element={
            <TopNavLayout>
              <Pharmacie />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/cosmetique"
          exact={true}
          element={
            <TopNavLayout>
              <Cosmetique />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/plan-de-travail"
          exact={true}
          element={
            <TopNavLayout>
              <PlanTravail />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/armoire-securite"
          exact={true}
          element={
            <TopNavLayout>
              <ArmoireSecurite />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/sieges-tabourets"
          exact={true}
          element={
            <TopNavLayout>
              <Tabourets />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/hotte-sorbonne"
          exact={true}
          element={
            <TopNavLayout>
              <HotteSorbonne />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/douches"
          exact={true}
          element={
            <TopNavLayout>
              <Douches />
            </TopNavLayout>
          }
        ></Route>

        <Route
          path="/tables-antivibration"
          exact={true}
          element={
            <TopNavLayout>
              <Tables />
            </TopNavLayout>
          }
        ></Route>

        <Route
          path="/mobilier-laboratoire"
          exact={true}
          element={
            <TopNavLayout>
              <Mobiliers />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/laveries"
          exact={true}
          element={
            <TopNavLayout>
              <Laverie />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/type-paillase"
          exact={true}
          element={
            <TopNavLayout>
              <TypePaillase />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/industrie"
          exact={true}
          element={
            <TopNavLayout>
              <Industrie />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/enseignement"
          exact={true}
          element={
            <TopNavLayout>
              <Enseignement />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/agro-alimentaire"
          exact={true}
          element={
            <TopNavLayout>
              <Agro />
            </TopNavLayout>
          }
        ></Route>
        <Route
          path="/about"
          exact={true}
          element={
            <TopNavLayout>
              <About />
            </TopNavLayout>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
