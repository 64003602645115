import { Row, Col } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
};
const Contact = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/contact.jpg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh",
            }}
          >
            <div>
              <span className="bl--about-title">Adresse</span>
            </div>
            <p className="bl--description">
              Av. Fethi Zouhir, Rés. L’Oasis, 2émé etage,
              <br />
              App. N° 4 cité la Ghazelle, 2083-Ariana.
            </p>
            <div>
              <span className="bl--about-title">Téléphone:</span>
            </div>
            <p className="bl--description">
              + 216 71 877 371
              <br />+ 216 71 877 154
            </p>
            <div>
              <span className="bl--about-title">Email:</span>
            </div>
            <p className="bl--description">best.lab@topnet.tn</p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row style={{ backgroundColor: "#fff", height: "8vh" }}></Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>{" "}
    </StyleRoot>
  );
};

export default Contact;
