import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const HotteSorbonne = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/hotte/slider/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Hottes sorbonne"
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <p className="bl--title-el">
                  Fourniture et pose d'une hotte sorbonne:
                </p>
                Pour la manipulation des produits chimiques toxiques <br />
                Dim. Ext. en (mm) L x P x H : 1428 x 980 x 2480. <br />
                Dim. Int. En (mm) L x P x H: 1200 x 750 x 1230.
                <br /> Ou dimension personalisée sur demande.
                <p className="bl--title-el">Structure et Châssis :</p>
                Ossature en acier peint de résine résistante aux acides et aux
                solvants organiques, réalisée selon les recommandations des
                bonnes pratiques de fabrication en zone propre. Toutes les
                soudures seront soigneusement poncées à l'extérieur et décapées
                à l'intérieur. Toutes les jonctions seront étanchées sur place.
                Fabriquée avec des panneaux agglomérés hydrofuge revêtus de
                laminé ignifuge Le panneau de fermeture est en verre sécurit
                d'épaisseur suffisante 6 mm. Fermeture et ouverture de la
                Sorbonne en face avant par une guillotine électrique pour
                faciliter l'ouverture de l'écran avec système antichute,
                commandes sur bandeau latéral et ouverture maximum 600 mm.
                Eclairage étanche (avec protection inactinique) intégré dans le
                plafond sur le plan de travail Bandeau intégrant la commande à
                distance d'un robinet d'eau, l'éclairage et 2 prises de courants
                220V/16A.
                <p className="bl--title-el">Plan de travail :</p>
                Plan de travail en plaque de grès étiré antiacide (produit
                certifié ISO 9001, testé par l'organisme SGS et ayant une
                excettente résistance chimique, mécanique, thermique, ...),
                équipé de : évier en polypropylène antiacide de 300x150 mm sur
                le côté avec un robinet d'eau à commande à distance.
                <p className="bl--title-el"> Equipements :</p>
                <p>
                  1 extracteur centrifuge à turbine en polypropylène antiacide
                  débit réglable jusqu'à 1100 m3 /h.
                </p>
                <p> 1 moteur électrique à 2800tr/min .</p>
                <p>
                  {" "}
                  Le système moteur /extracteur est placé à l'extérieur du
                  laboratoire.
                </p>
                <p> 1 variateur de la vitesse avec affichage.</p>
                <p className="bl--title-el">Elément bas (OPTION):</p>
                <p>
                  L'élément bas solidaire de l'élément haut par jonction
                  mécanique est connecté par le système d'évacuation d'air est
                  de construction identique résistante aux agents corrosif pour
                  le stockage des acides et des base.
                </p>
              </Col>
              <Col xl={12} md={12} xs={24} style={{ float: "right" }}>
                <img src="/images/hotte/1.png"></img>
                <img src="/images/hotte/2.png"></img>
                <img src="/images/hotte/3.png"></img>
                <img src="/images/hotte/4.png"></img>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Extracteur"
            bordered={false}
          >
            Pour permettre une aspiration correcte des émanations de gaz ou
            vapeurs plus ou moins toxique lors des manipulations, il existe le
            sélection du système d'aspiration.
            <p className="bl--title-el">Sélection du système de ventilation</p>
            Ventilateurs centrifuges résistants aux acides Ventilateur
            centrifuge industriels pour évaluation des fumées et fluides
            corrosifs.
            <ul>
              <li>
                Volute : estampée en polyéthylène orientable en huit positions.
              </li>
              <li>
                Turbine : à aubes (pales) inclinées vers l'arrière en
                polypropylène.
              </li>
              <li>
                Siège porte moteur : en tole d'acier revetue en poudre époxy.
              </li>
              <li>Vis : en acier inoxydable.</li>
            </ul>
            <div style={{ textAlign: "center" }}>
              <img src="/images/hotte/5.png"></img>
            </div>
            <p className="bl--title-el">Encombrement</p>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: "100%" }} src="/images/hotte/6.jpg"></img>
            </div>
            <p className="bl--title-el">Orientations</p>
            <div style={{ textAlign: "center" }}>
              <img style={{ width: "100%" }} src="/images/hotte/7.jpg"></img>
            </div>
          </Card>
        </Col>
      </Row>
      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(15)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default HotteSorbonne;
