import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const PlanTravail = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;
    if (x == 4) {
      return "/images/utilites/slider/" + x + ".JPG";
    }
    return "/images/utilites/slider/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">les plans de travail</div>
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Plan de travail en Grès antiacide"
            bordered={false}
          >
            Dalles de grès épaisseur 6mm posées sur un support en bois mélaminé
            sur les 2 faces, hydrofuge, épaisseur 18 mm (épaisseur totale 24
            mm), ou en dalle de béton révetue par des carreaux de grès anti
            acide excellente résistance chimique, excellente résistance
            mécanique
            <div style={{ textAlign: "center" }}>
              <img src="/images/plan/1.PNG"></img>
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Plan de travail en Glace émaillée (Verre trempé)"
            bordered={false}
          >
            teinte opaline, épaisseur du verre 6 mm, plan verre posé sur support
            MDF, épaisseur 18 mm (épaisseur totale 24 mm), surface parfaitement
            lisse idéale pour le nettoyage, la décontamination et la
            stérilisation
            <div style={{ textAlign: "center" }}>
              <img src="/images/plan/2.PNG"></img>
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Plan de travail en Composite"
            bordered={false}
          >
            Revêtement antibactérien, antiacide, réparable, résistant aux
            produits de nettoyage. Les paillasses seront monoblocs, sans joint
            et respectant les normes en vigueur. Les surfaces seront
            résisitantes aux rayures et à l'abrasion, bonne résistance aux
            tâches et bonnes résistance thermique. l'épaisseur du composite est
            de 16 mm minimum en fonction de la forme et dimension de la
            paillasse. Avec dosseret et tablette en continuité avec le plan de
            travail (sans jointure).
            {/* <div style={{ textAlign: "center" }}>
              <img src="/images/plan/3.PNG"></img>
            </div> */}
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Plan de travail en Grés étiré"
            bordered={false}
          >
            Le quartz technologique est un matériau composé à 95% de quartz
            naturel auquel on ajoute des piments et des résines.
            <br /> Le mélange est compacté par vibrocompression sous vide, selon
            le procédé technologique engineered stone, de manière à obtenir un
            matériau très résistant et d'une haute qualité décorative.
            <br /> Le cristal de quartz est l'un des minéraux les plus
            abondants, les plus durs et les plus beaux existant dans la nature.
            Sa dureté sur l'échelle de Mohs est parmi les quatres plus élevées,
            après le diamant, le corindon et la topaze Grès étiré en plaque de
            1000 à 3000mm et de 20 mm d'épaisseur.
            <div style={{ textAlign: "center" }}>
              <img src="/images/plan/3.PNG"></img>
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Plan de travail en LAMICA Compact"
            bordered={false}
          >
            Le LAMICA compact est un panneau stratifié. La constitution homogène
            du panneau LAMICA compact lui confère les propriétés suivantes :
            <br />
            Résistance particulière à l'eau et à la vapeur, auto-portance et
            résistance élevée aux charges et aux chocs, hygiène avec une
            aptitude à une désinfection totale aisée, planéité et stabilité
            dimensionnelle,bonne isolation électrique.
            <div style={{ textAlign: "center" }}>
              <img src="/images/plan/5.PNG"></img>
            </div>
          </Card>
        </Col>
      </Row>
      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(9)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default PlanTravail;
