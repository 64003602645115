import { Row, Col, Card, Button, Typography } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const ArmoireSecurite = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/laverie/IMG" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">les armoires de sécurité</div>
        </Row>
        <Row
          style={{
            backgroundColor: "#00738e",
            paddingBottom: "4vh",
            marginTop: "1vh"
          }}
        >
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/armoire.jpeg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              Tous les produits chimique toxique ou dangereux doivent être
              stockés dans des armoires adaptées pour assurer la protection des
              personnes, des produits et prévenir tout pollution accidentelle.
            </p>
            <p className="bl--description">
              Il existe différents types d'armoires de sécurité:
              <ul>
                <li>
                  Armoire acides-bases: permettent de stocker les produits
                  corresifs.
                </li>
                <li>
                  Armoire inflammables : permettent de stocker tous les
                  solvants.
                </li>
                <li>
                  Armoire combinée : il est important de bien stocker les
                  produits dangereux séparément les uns des autres.
                </li>
              </ul>
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>

      <Row
        justify="center"
        style={{
          backgroundColor: "#5fb3c3",
          paddingBottom: "2vh",
          marginTop: "2vh"
        }}
      >
        <div className="bl--sub-title-page">Les armoires acides-bases</div>
      </Row>

      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ ASPIRÉE POUR LE STOCKAGE DE 15 LITRES <br />{" "}
                DE PRODUITS CHIMIQES,ACIDES ET BASES, A 600/50
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                {" "}
                <ul>
                  <li>
                    Entièrement construite en acier décapé et électrozingué,
                    épaisseur 10/10 avec peinture aux poudres époxy antiacide et
                    passage dans un tunnel thermique à 200° C.
                  </li>
                  <li> Soubassement palettisé certifié. </li>
                  <li>Structure externe monolithique entièrement soudée.</li>
                  <li>
                    Aménagement pour filtre aux charbons actifs granulés.{" "}
                  </li>
                  <li>Aspirateur électrique IMQ.</li>
                  <li>Dimensions extérieures : mm 600 x 500 x 720 (620) h</li>
                  <li>Dimensions intérieures : mm 530 x 460 x 560 h</li>
                  <li>Poids: Kg 45</li>
                  <li>Volume interne: 140 litres </li>
                  <li>
                    Accessoires : Filtre à charbon actif granulé Carbox® (cod.
                    F007) Filtre pour formaldehyde (cod. F007F)
                  </li>
                  <li>Étagères supplémentaires</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3", height: "100%" }}
                    src="/images/armoires/1.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR LE STOCKAGE <br />{" "}
                DE 70 LITRES DE PRODUITS CHIMIQUES, ACIDES ET BASES AA 600.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/2.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>
                    Entièrement construite en acier décapé et electrozingué,
                    épaisseur 10/10 avec peinture aux poudres époxy antiacide et
                    passage dans un tunnel thermique à 200° C.
                  </li>
                  <li> Soubassement palettisé certifié. </li>
                  <li>Structure externe monolithique entièrement soudée.</li>
                  <li>3 étagères réglables en hauteur </li>
                  <li> Dimensions extérieures en mm: 600 x 500 x 1998 h</li>
                  <li>Dimensions intérieures en mm : 530 x 450 x 1500 h</li>
                  <li>Poids: Kg 96</li>
                  <li>Volume interne: 357 litres</li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR LE STOCKAGE DE{" "}
                <br /> 160 LITRES DE PRODUITS CHIMIQUES, ACIDES ET BASES, AA
                120.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 1200 x 500 x 1998 h</li>
                  <li> Dimensions intérieures en mm: 1120 x 450 x 1500 h </li>
                  <li>Poids: Kg 155</li>
                  <li>Volume interne : 766 litres</li>
                  <li> Accessoires :</li>
                  <li>Filtre pour formaldehyde (code F007F)</li>
                  <li>Étagères supplémentaires</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/3.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR LE STOCKAGE DE{" "}
                <br />
                70 LITRES DE PRODUITS CHIMIQUES, ACIDES ET BASES, AAW 600.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/4.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>
                    Entièrement construite en acier décapé et electrozingué,
                    épaisseur 10/10 avec peinture aux poudres époxy antiacide et
                    passage dans un tunnel thermique à 200° C.
                  </li>
                  <li> Soubassement palettisé certifié.</li>
                  <li>Structure externe monolithique entièrement soudée.</li>
                  <li>3 étagères réglables en hauteur</li>
                  <li> Dimensions extérieures en mm : 600 x 500 x 1998 h</li>
                  <li>Dimensions intérieures en mm : 530 x 450 x 1500 h</li>
                  <li>Poids: Kg 96</li>
                  <li>Volume interne : 357 litres</li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR <br />
                LE STOCKAGE DE 90 LITRES DE PRODUITS CHIMIQUES, ACIDES ET BASES,
                AB 600
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>
                    2 compartiments séparés, l'un pour les acides et l'autre
                    pour les bases.
                  </li>
                  <li> Dimensions extérieures en mm: 600 x 500 x 1998 h.</li>
                  <li>Dimensions intérieures en mm : 520 x 440 x 765+726 h</li>
                  <li>Volume interne : 341 litres</li>
                  <li> Accessoires :</li>
                  <li> système d'économie d'énergie</li>
                  <li>Filtre pour formaldehyde (code F007F) </li>
                  <li>Étagères supplémentaires</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/5.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 160 LITRES <br /> DE
                PRODUITS LIQUIDES ET SOLIDES INFLAMMABLES, AC 1200 T30.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/6.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm : 1180 x 650 x 1985 h</li>
                  <li> Hors tout maximum profondeur mm : 710</li>
                  <li>Dimensions intérieures en mm : 1019 x 477 x 1688 h</li>
                  <li>Poids: kg 415</li>
                  <li>Volume interne : environ 820 litres</li>
                  <li> Accessoires :</li>
                  <li>
                    {" "}
                    Possibilité de doter l'armoire de filtres à charbons actifs
                    et de moteur.
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 160 LITRES <br />
                DE PRODUITS LIQUIDES ET SOLIDES INFLAMMABLES, AC 1200/50 CM
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 680 x 640 x 1950 h</li>
                  <li> Hors tout maximum profondeur mm: 689</li>
                  <li>Dimensions intérieures en mm: 533 x 495 x 1731 h</li>
                  <li>Poids: kg 252</li>
                  <li>Volume interne : environ 456 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/7.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row
        justify="center"
        style={{
          backgroundColor: "#5fb3c3",
          paddingBottom: "2vh",
          marginTop: "2vh"
        }}
      >
        <div className="bl--sub-title-page">Les armoires inflammables</div>
      </Row>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 100 LITRES DE PRODUITS
                <br /> LIQUIDES ET SOLIDES INFLAMMABLES, AC 600 T30.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 680 x 650 x 1985 h</li>
                  <li> Hors tout maximum profondeur mm : 740</li>
                  <li>Dimensions intérieures en mm: 517 x 468 x 1688 h</li>
                  <li>Poids: kg 350</li>
                  <li>Volume interne : environ 456 litres</li>
                  <li>
                    Accessoires : Possibilité de doter l'armoire de filtres à
                    charbons actifs et de moteur.
                  </li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/8.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 100 LITRES DE PRODUITS
                <br /> LIQUIDES ET SOLIDES INFLAMMABLES, AC 600 CM.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/8.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 680 x 650 x 1 985 h</li>
                  <li>Hors tout maximum profondeur mm : 740</li>
                  <li>Dimensions intérieures en mm : 517 x 468 x 1 688 h</li>
                  <li>Poids: kg 350</li>
                  <li>Volume interne : environ 408 litres</li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 15 LITRES DE PRODUITS
                <br /> LIQUIDES ET SOLIDES I NFLAMMABLES, AC 600/50 CM.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 595 x 510 x 600/620 h</li>
                  <li>Hors tout maximum profondeur mm : 600</li>
                  <li>Dimensions intérieures en mm : 430 x 355 x 440 h</li>
                  <li>Poids: kg 100</li>
                  <li>Volume interne : environ 67 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/9.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 25 LITRES
                <br /> DE PRODUITS LIQUIDES ET SOLIDES INFLAMMABLES, AC 900/50
                CM.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 900 x 510 x 600/620 h</li>
                  <li>Hors tout maximum profondeur mm : 570</li>
                  <li>Dimensions intérieures en mm : 730 x 355 x 440 h</li>
                  <li>Poids: kg 146</li>
                  <li>Volume interne : environ 114 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/11.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 160 LITRES
                <br /> DE PRODUITS LIQUIDES ET SOLIDES INFLAMMABLES, AC 1200 CM.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/6.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 1180 x 650 x 1985 h</li>
                  <li>Hors tout maximum profondeur mm : 710</li>
                  <li>Dimensions intérieures en mm : 1019 x 477 x 1 688 h</li>
                  <li>Poids: kg 490</li>
                  <li>Volume interne : environ 820 litres</li>
                  <li>
                    Accessoire: Possibilité de doter l'armoire de filtres à
                    charbons actifs et de moteur.
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ POUR LE STOCKAGE DE 160 LITRES <br />
                DE PRODUITS LIQUIDES ET SOLIDES INFLAMMABLES, AC 1200 T30.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: 1180 x 650 x 1985 h</li>
                  <li>Hors tout maximum profondeur mm : 710</li>
                  <li>Dimensions intérieures en mm : 1019 x 477 x 1 688 h</li>
                  <li>Poids: kg 415</li>
                  <li>Volume interne : environ 820 litres</li>
                  <li>
                    Accessoire: Possibilité de doter l'armoire de filtres à
                    charbons actifs et de moteur.
                  </li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/6.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row
        justify="center"
        style={{
          backgroundColor: "#5fb3c3",
          paddingBottom: "2vh",
          marginTop: "2vh"
        }}
      >
        <div className="bl--sub-title-page">Les armoires combinées</div>
      </Row>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR <br />
                LE STOCKAGE COMBINÉ 600 A type A
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: mm 595 x 510 x 1670 h</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases: mm 510 x 474 x 924 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables : mm 430 x395 x 440 h
                  </li>
                  <li>Poids: kg 185</li>
                  <li>Volume interne : environ 220 + 75 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/12.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ ASPIRÉE POUR <br /> LE STOCKAGE COMBINÉ,
                1000/50 Type A.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/13.png"
                  />
                </div>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures en mm: mm 995 x 510 x 600 h</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases en mm: 320 x 460 x 560 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables: mm 430 x 355 x 440 h
                  </li>
                  <li>Poids: kg 140</li>
                  <li>Volume interne : environ 85 + 75 litres</li>
                  <li>
                    {" "}
                    Accessoire Pour compartiment pour produits chimiques Filtre
                    aux charbons actifs granulés.
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE <br />
                POUR LE STOCKAGE COMBINÉ, 1100 A Type A.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures : mm 1100 x 510 x 1670</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases en mm : 490+490 x 465 x 1020 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables: mm 936 x 395 x 440 h
                  </li>
                  <li>Poids: kg 300</li>
                  <li>Volume interne : environ 465 + 164 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/14.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE <br />
                POUR LE STOCKAGE COMBINÉ 1200/50 type A
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures : mm 1195 x 510 x 600 h</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases: mm 510 x 470 x 551 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables: mm 4430 x 355x 440 h
                  </li>
                  <li>Poids: kg 145</li>
                  <li>Volume interne : environ 140 + 67 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/11.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ ASPIRÉE POUR LE STOCKAGE COMBINÉ,
                <br /> 1500/50 Type C.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={24} md={24} xs={24}>
                <ul>
                  <li>Dimensions extérieures: mm 1495 x 510 x 600 h</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases en mm: 380+380 x 470 x 551 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables: mm 430 x 355 x 440 h
                  </li>
                  <li>Poids: kg 210</li>
                  <li>Volume interne : environ 224 + 75 litres</li>
                  <li>
                    Accessoire: Pour compartiment pour produits chimiques Filtre
                    aux charbons actifs granulés.
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                ARMOIRE DE SÉCURITÉ FILTRÉE ET ASPIRÉE POUR <br />
                LE STOCKAGE COMBINÉ, 1100 A Type A.
              </div>
            }
            bordered={false}
          >
            <Row>
              <Col xl={12} md={12} xs={24}>
                <ul>
                  <li>Dimensions extérieures : mm 11495 x 510 x 600 h</li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    chimiques, acides et bases en mm : 320 x 460 x 560 h
                  </li>
                  <li>
                    Dimensions intérieures du compartiment pour produits
                    inflammables: mm 936 x 355 x 440 h
                  </li>
                  <li>Poids: kg 210</li>
                  <li>Volume interne : environ 85 + 146 litres</li>
                </ul>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3" }}
                    src="/images/armoires/15.png"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default ArmoireSecurite;
