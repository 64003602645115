import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Douches = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/tables/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">Douches et lave-yeux</div>
        </Row>
        <Row
          style={{
            backgroundColor: "#00738e",
            paddingBottom: "4vh",
            marginTop: "2vh"
          }}
        >
          <Col xl={2} md={2} xs={1} />
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{ textAlign: "center", paddingTop: "1vh" }}
          >
            <img src="/images/douches/3.png" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              Fabriquées conformément aux normes,elles sont caractérisées par un
              débit d'eau élevé ainsi que par une projection aérée permettant de
              rincer immédiatement les parties du corps contaminées
              accidentellement par des produits agressifs.
            </p>
            <p className="bl--description">
              Indispensables dans tous les environnements à haut risque, elles
              sont aisément identifiables par leur couleur verte (ou sur
              demande) et par un panneau approprié conformément aux
              prescriptions des normes en vigueur.
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row
        justify="center"
        style={{
          backgroundColor: "#5fb3c3",
          paddingBottom: "2vh",
          marginTop: "2vh"
        }}
      >
        <div className="bl--sub-title-page">Lave-yeux de sécurité</div>
      </Row>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Lave-yeux de sécurité mural"
            bordered={false}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ border: "1px solid #5fb3c3", height: "100%" }}
                src="/images/douches/1.png"
              />
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Combiné douche de sécurité et lave-yeux sur colonne"
            bordered={false}
          >
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <img
                style={{ border: "1px solid #5fb3c3" }}
                src="/images/douches/2.png"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row
        justify="center"
        style={{
          backgroundColor: "#5fb3c3",
          paddingBottom: "2vh",
          marginTop: "2vh"
        }}
      >
        <div className="bl--sub-title-page">Lave-yeux de sécurité</div>
      </Row>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Lave-yeux sur colonne"
            bordered={false}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ border: "1px solid #5fb3c3", height: "100%" }}
                src="/images/douches/3.png"
              />
            </div>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Douches murales simple ou combinés"
            bordered={false}
          >
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <img
                style={{ border: "1px solid #5fb3c3" }}
                src="/images/douches/4.png"
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(8)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row> */}

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
            marginTop: "2vh"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Douches;
