import { Row, Col, Card } from "antd";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { bounce } from "react-animations";
import { fadeInRight } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { SampleNextArrow, SamplePrevArrow } from "./Utils";
import { settings } from "../Settings";

const styles = {
  bounce: {
    animation: "x 1s",
    animationName: Radium.keyframes(bounce, "bounce")
  },
  fadeInRight: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight")
  }
};

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <StyleRoot>
      <div style={styles.fadeInRight}>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={22} xs={23}>
            <img src="/images/1.png" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={22}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description-vert" style={{ marginTop: "8vh" }}>
              Pourquoi nous sommes les meilleurs
            </p>
            <p className="bl--description" style={{ marginTop: "4vh" }}>
              BEST LAB vous propose un savoir, un savoir-faire et une expérience
              de plusieurs années aux services de nos clients dans les secteurs
              suivants :
            </p>
            <br /> <br />
            <ul className="bl--ul">
              <li>La recherche et enseignement supérieur</li>
              <li>L’environnement</li>
              <li>L’industrie ( pharmaceutiques , agroalimentaires …)</li>
              <li>Santé ( Hôpitaux , Laboratoires d’analyses…)</li>
            </ul>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={8} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Etude de votre projet"
            bordered={false}
          >
            <ul>
              <li>
                Proposer et conseiller les clients dans le cas d'un aménagement
                ou réamenagement de laboratoire.{" "}
              </li>
              <li>
                Un chargé du projet peut se déplacer sur site pour prendre
                précisément les mesures de l'espace à équiper.
              </li>
              <li>
                Notre société propose la réalisation des plans 2D ainsi que des
                vues en 3D afin de modéliser votre future laboratoire.
              </li>
            </ul>
          </Card>
        </Col>
        <Col xl={8} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Installation du mobilier de laboratoire"
            bordered={false}
          >
            <ul>
              <li>
                Notre société est à votre disposition pour réaliser
                l'installation de votre laboratoire par des équipes d'artisans.
              </li>
              <li>Nous intervenons sur toute la tunisie.</li>
              Nous proposons les prestations suivantes :
              <li>
                installation des paillasses, laveries, robinets, prises
                électrique et informatique, raccordement électrique et eau
              </li>
              <li>
                installation des meubles muraux suspendus et meubles sous
                paillasses
              </li>
              <li>démontage et réinstallation les hottes chimiques.</li>
            </ul>
          </Card>
        </Col>
        <Col xl={8} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Caractéristiques générales"
            bordered={false}
          >
            <ul>
              <li>
                <span className="bl--title-ul"> Piètement métallique:</span>{" "}
                protégé par résine époxy cuite au four, couleurs au choix.
              </li>
              • 4 vérins réglables de mise à niveau <br />• bandeau de façade en
              mélaminé
              <li>
                {" "}
                <span className="bl--title-ul">Cache-fluide:</span> panneau
                d'ossature et d'habillage fixé à l'intérieur des piétements, à
                l'arrière, deux faces mélaminées blanches, tous les chants sont
                plaqués, épaisseur 16 mm
              </li>
              <li>
                {" "}
                <span className="bl--title-ul">Plan de travail:</span> fixé sur
                cadre métallique, protégé par résine époxy cuite au four.
              </li>
              • hauteur du plan de travail : 900 mm
              <li>
                <span className="bl--title-ul">Dosseret et tablettes:</span>
                épaisseur 18 mm, dosseret percé pour robinetterie et prises
                électriques, tablette et chant couleur au choix.
              </li>
            </ul>
          </Card>
        </Col>
      </Row>

      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              <div>
                <img
                  className="bl--slider-img bl--slider-about "
                  src="/images/about/1.PNG"
                />
              </div>
              <div>
                <img
                  className="bl--slider-img bl--slider-about"
                  src="/images/about/2.PNG"
                />
              </div>
              <div>
                <img
                  className="bl--slider-img bl--slider-about"
                  src="/images/about/3.PNG"
                />
              </div>
              <div>
                <img
                  className="bl--slider-img bl--slider-about"
                  src="/images/about/4.PNG"
                />
              </div>
              <div>
                <img
                  className="bl--slider-img bl--slider-about"
                  src="/images/about/5.PNG"
                />
              </div>
              <div>
                <img
                  className="bl--slider-img bl--slider-about"
                  src="/images/about/6.PNG"
                />
              </div>
            </Slider>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default About;
