import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Tabourets = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/tables/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">Sièges et Tabourets </div>
        </Row>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/tabourets.JPG" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              BEST LAB propose une gamme de sièges de laboratoire avec une
              assise très ergonomique pour un confort maximum, une position de
              travail idéale et une posture dynamique permettant de bouger
              facilement.
            </p>
            <p className="bl--description">
              Les critères de choix sont les suivants :
              <ul>
                <li>Le modèle : des tabourets assis-debout, des chaises.</li>
                <li>
                  Le support : les sièges sont montés sur une piètement avec 5
                  points d'appui, avec des roulettes ou des patins.
                </li>
                <li>
                  Le repose pied: certains modèles sont munis d'un repose pied
                  pour soulager l'utilisateur et favoriser une bonne circulation
                  sanguine.
                </li>
                <li>
                  Le matériau du siège : mousse de polyuréthane en
                  polypropylène.
                </li>
              </ul>
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={8} md={8} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                Assis-debout en polyuréthane <br /> avec micro-dossier
              </div>
            }
            bordered={false}
          >
            <ul>
              <li>Assise et dossier en polyuréthane injecté</li>
              <li>Platine fixe sous assise</li>
              <li>Réglage en hauteur par vérin à gaz</li>
            </ul>
            <div style={{ textAlign: "center" }}>
              <img src="/images/tabouret/1.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                Sièges <br /> Confort
              </div>
            }
            bordered={false}
          >
            <ul>
              <li>Tension réglable latéralement anti-retour.</li>
              <li>Translation d'assise.</li>
              <li>Accoudoirs réglables.</li>
              <li>Lift de réglage en hauteur.</li>
            </ul>
            <div style={{ textAlign: "center" }}>
              <img src="/images/tabouret/2.png"></img>
            </div>
          </Card>
        </Col>
        <Col xl={8} md={8} xs={24} style={{ marginTop: "1vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title={
              <div>
                Sièges vinyle <br /> contact synchrone
              </div>
            }
            bordered={false}
          >
            <ul>
              <li> Mouvement simultané de l'assise et du dossier</li>
              <li>
                Assise et dossier en mousse injectée, revêtement vinyle, coloris
                au choix
              </li>
              <li>Molette blocage dans toutes les positions</li>
            </ul>
            <div style={{ textAlign: "center" }}>
              <img src="/images/tabouret/3.png"></img>
            </div>
          </Card>
        </Col>
      </Row>

      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "8vh",
            textAlign: "center",
            marginTop: "2vh"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Tabourets;
