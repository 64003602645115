import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Utilite = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;
    if (x == 4) {
      return "/images/utilites/slider/" + x + ".JPG";
    }
    return "/images/utilites/slider/" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/service.jpg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh"
            }}
          >
            <p className="bl--description">
              BEST Lab propose une gamme très complète de robinets pour
              l'alimentation d'eau de vos laboratoires suivant les besoins.
            </p>
            <p className="bl--description">
              Robinetterie de laboratoire conforme aux normes.
              <br />
              Robinets robustes, en laiton, coloris gris avec revêtement époxy
              garantissant une bonne résistance aux produits employés dans les
              laboratoires.
            </p>
            <p className="bl--description">
              Les pastilles et les volants des robinets sont de couleurs
              distinctes pour une identification aisée des différents fluides
              (eau, gaz, air, azote) Il existe deux positionnements des robinets
              : sur table, sur dosseret. Robinet sur plan de travail
            </p>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Robinet sur plan de travail"
            bordered={false}
          >
            <Row justify="center">
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img1}
                  />
                  <p className="bl--title-el">
                    Eau froide col de cygne sur table
                  </p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <ReactRoundedImage
                  roundedSize="8"
                  roundedColor="#5fb3c3"
                  style={{ textAlign: "center" }}
                  image={img8}
                />
                <p className="bl--title-el">Eau chaude/Eau froide</p>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <ReactRoundedImage
                  roundedSize="8"
                  roundedColor="#5fb3c3"
                  image={img2}
                />
                <p className="bl--title-el">Robinet gaz double</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Robinet sur dosseret"
            bordered={false}
          >
            <Row justify="center">
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img3}
                  />
                  <p className="bl--title-el">Robinet eau droite</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <ReactRoundedImage
                  roundedSize="8"
                  roundedColor="#5fb3c3"
                  style={{ textAlign: "center" }}
                  image={img5}
                />
                <p className="bl--title-el">
                  Eau froide col de cygne sur dosseret
                </p>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <ReactRoundedImage
                  roundedSize="8"
                  roundedColor="#5fb3c3"
                  image={img4}
                />
                <p className="bl--title-el">Robinet gaz simple</p>
              </Col>
            </Row>
          </Card>
        </Col>{" "}
        <Col xl={12} md={12} xs={24} style={{ marginTop: "2vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Becs avec commande à distance pour eau, gaz, air comprimé..."
            bordered={false}
          >
            <ul>
              {/* <li>
                robinet commandé à distance, type laboratoire, en laiton,
                coloris gris avec revOtement epoxy garantissant une bonne
                resistance aux produits employes dans les laboratoires.
              </li> */}
              <li>La commande a distance est une poignee manuelle</li>
              <Row justify="center" style={{ marginTop: "2vh" }}>
                <Col xl={8} md={12} xs={24}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img6}
                  />
                </Col>
                <Col xl={8} md={12} xs={24}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img10}
                  />
                </Col>
              </Row>
            </ul>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24} style={{ marginTop: "2vh" }}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Eléctricité"
            bordered={false}
          >
            Notre sociéte peut equipé votre laboratoire d'une large gamme des
            prises.
            <Row justify="center" style={{ marginTop: "2vh" }}>
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img12}
                  />
                  <p className="bl--title-el">Prise de courant étanche 220 V</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img13}
                  />
                  <p className="bl--title-el">Prise informatique RJ45</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img14}
                  />
                  <p className="bl--title-el">Prise courant 220 V</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img15}
                  />
                  <p className="bl--title-el">Prise male 3P+T IP44</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24}>
                <div style={{ textAlign: "center" }}>
                  <ReactRoundedImage
                    roundedSize="8"
                    roundedColor="#5fb3c3"
                    image={img11}
                  />
                  <p className="bl--title-el">Prise murale IP44 </p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(9)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Utilite;
