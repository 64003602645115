import { Row, Col, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
};
const Services = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row style={{ backgroundColor: "#00738e", paddingBottom: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={10} md={10} xs={23}>
            <img src="/images/service.jpg" className="bl--img-page" />
          </Col>
          <Col
            xl={10}
            md={10}
            xs={23}
            style={{
              marginTop: "2vh",
              marginLeft: "1vh",
            }}
          >
            <p className="bl--description">
              BEST LAB se dispose d’une gamme importante de matériel scientiﬁque
              et de laboratoire destinée pour se servir dans plusieurs domaines
              :
            </p>
            <br /> <br />
            <ul className="bl--ul">
              <li>Biotechnologies Analyse de l’eau</li>
              <li>Stations de traitement de l’eau :osmosée et bi-osmosée</li>
              <li>Mesures</li>
              <li>Techniques de laboratoire</li>
              <li>Aménagement des laboratoires : paillasses, mobiliers</li>
              <li>et sorbonnes</li>
            </ul>
          </Col>

          <Col xl={2} md={2} xs={1} />
        </Row>
      </div>
      <Row style={{ padding: "4vh" }}>
        <Col xl={2} md={0} xs={0} />
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/1.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasse Revêtement</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Plan de travail en grès antiacide
              </div>
              <div className="bl--subtitle-img">
                Plan de travail en verre émmailée
              </div>
              <div className="bl--subtitle-img">
                Plan de travail en composite
              </div>
              <div className="bl--subtitle-img">Pal de travail en Quartez</div>
            </div>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/2.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasses murales</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Paillasse murale sans dosseret
              </div>
              <div className="bl--subtitle-img">
                Paillasse murale avec dosseret / tablette{" "}
              </div>
            </div>
          </div>
        </Col>

        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/3.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Paillasses centrales</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Paillasse centrale avec dosseret / tablette
              </div>
              <div className="bl--subtitle-img">
                Paillasse centrale avec cuve
              </div>
            </div>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12}>
          <div class="container">
            <img src="/images/4.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Meubles sous paillasses</span>
            </div>
            <div>
              <div className="bl--subtitle-img">Meuble à deux portes</div>
              <div className="bl--subtitle-img">
                Meuble à porte avec tiroirs
              </div>
              <div className="bl--subtitle-img">Meuble à tiroirs</div>
            </div>
          </div>
        </Col>
        <Col xl={2} md={0} xs={0} />

        <Col xl={2} md={0} xs={0} />
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/5.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <span className="bl--title-img">Hotte Sorbonne</span>
          </div>
        </Col>
        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/6.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <span className="bl--title-img">Table de pesé</span>
          </div>
        </Col>

        <Col xl={5} md={8} xs={12} style={{ textAlign: "center" }}>
          <div class="container">
            <img src="/images/7.png" class="image" />
            <div class="overlay"></div>
          </div>
          <div style={{ width: "80%" }}>
            <div>
              <span className="bl--title-img">Cuve et Robinetterie</span>
            </div>
            <div>
              <div className="bl--subtitle-img">
                Robinet eau chaude / eau froide
              </div>
              <div className="bl--subtitle-img">Air comprimé</div>
            </div>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center",
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Services;
