import { Row, Col, Card, Button } from "antd";
import { useEffect } from "react";
import { fadeIn } from "react-animations";
import { fadeInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ReactRoundedImage from "react-rounded-image";
import img1 from "../../images/utilites/1.PNG";
import img2 from "../../images/utilites/2.PNG";
import img3 from "../../images/utilites/3.PNG";
import img4 from "../../images/utilites/4.PNG";
import img5 from "../../images/utilites/5.PNG";
import img6 from "../../images/utilites/6.PNG";
import img8 from "../../images/utilites/8.PNG";
import img10 from "../../images/utilites/10.PNG";
import img11 from "../../images/utilites/11.png";
import img12 from "../../images/utilites/12.png";
import img13 from "../../images/utilites/13.png";
import img14 from "../../images/utilites/14.png";
import img15 from "../../images/utilites/15.png";
import { settings } from "../../Settings";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  },
  fadeInLeft: {
    animation: "x 2s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft")
  }
};
const Laverie = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getImages = (x) => {
    x++;

    return "/images/laverie/IMG" + x + ".jpg";
  };
  return (
    <StyleRoot>
      <div style={styles.fadeInLeft}>
        <Row
          justify="center"
          style={{ backgroundColor: "#00738e", paddingBottom: "2vh" }}
        >
          <div className="bl--title-page">Laveries sur piètement métalique</div>
        </Row>
      </div>
      <Row gutter={32} style={{ margin: "4vh" }} flex="1">
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="laverie de"
            bordered={false}
          >
            <p className="bl--title-el"> Laverie sur piètement:</p>
            <p>
              Laveries sur piètement métallique protégé par résine époxy cuite
              au four couleur RAL 7035 ou autre.
            </p>
            <p className="bl--title-el"> Laverie sur meuble:</p>
            <p className="bl--title-el">
              Laverie sur meuble avec dosseret et tablette:
            </p>

            <Row gutter={32} flex="1">
              <Col xl={8} md={12} xs={24} style={{ display: "flex" }}>
                <div style={{ textAlign: "center", height: "90%" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3", height: "100%" }}
                    src="/images/laverie/1.png"
                  />
                  <p className="bl--title-el">Sur Piètement</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24} style={{ display: "flex" }}>
                <div style={{ textAlign: "center", height: "90%" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3", height: "100%" }}
                    src="/images/laverie/2.png"
                  />
                  <p className="bl--title-el">Sur Meuble</p>
                </div>
              </Col>
              <Col xl={8} md={12} xs={24} style={{ display: "flex" }}>
                <div style={{ textAlign: "center", height: "90%" }}>
                  <img
                    style={{ border: "1px solid #5fb3c3", height: "100%" }}
                    src="/images/laverie/3.png"
                  />
                  <p className="bl--title-el">
                    Sur Meuble avec Dosseret et Tablette
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Card
            style={{ height: "100%", marginTop: "1vh" }}
            className="bestlab--card-title"
            title="Cuves de laboratoire"
            bordered={false}
          >
            Cuves en inox, en céramique, en composite, en polypropylène PP{" "}
            <br />
            Dimension:(30x30, 35x35, 40x40, 45x45, 15x30) avec siphon
            anti-acide.
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <img
                style={{ border: "1px solid #5fb3c3" }}
                src="/images/laverie/4.png"
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row style={{ padding: "4vh" }}>
        <Col span={24}>
          <div>
            <Slider {...settings}>
              {Array.apply(null, Array(10)).map((el, x) => (
                <div>
                  <img
                    className="bl--slider-img bl--slider-about "
                    src={getImages(x)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
      <div style={styles.fadeIn}>
        <Row
          style={{
            backgroundColor: "#00738e",
            height: "6vh",
            textAlign: "center"
          }}
        >
          <Col span="24" style={{ textAlign: "center" }}>
            <p className="bl--description">
              Vente équipements, Verreries,accessoires et Produits chimiques
            </p>
          </Col>
        </Row>
      </div>
    </StyleRoot>
  );
};

export default Laverie;
